var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":'Equipment History ',"show":_vm.modalState,"color":"info","size":"xl"},on:{"update:show":function($event){_vm.modalState=$event}}},[_c('div',[_c('CRow',{staticClass:"mb-1"},[_c('CCol',{attrs:{"lg":"6"}},[_c('small',{staticClass:"mr-2"},[_vm._v("Classification")]),_c('CInput',{staticClass:"mb-2",attrs:{"disabled":"","value":_vm.details.classification,"type":"text"}})],1),_c('CCol',{staticClass:"mb-1",attrs:{"lg":"6"}},[_c('small',{staticClass:"mr-2"},[_vm._v("Asset No.")]),_c('CInput',{staticClass:"mb-2",attrs:{"disabled":"","value":_vm.details.asset_no,"type":"text"}})],1),_c('CCol',{staticClass:"mb-1",attrs:{"lg":"6"}},[_c('small',{staticClass:"mr-2"},[_vm._v("Equipment No")]),_c('CInput',{staticClass:"mb-2",attrs:{"disabled":"","value":_vm.details.equipment_prep_no,"type":"text"}})],1),_c('CCol',{staticClass:"mb-1",attrs:{"lg":"6"}},[_c('small',{staticClass:"mr-2"},[_vm._v("Current Location")]),_c('CInput',{staticClass:"mb-2",attrs:{"disabled":"","value":(_vm.details.current_location ? _vm.details.current_location.customer_name: ''),"type":"text"}})],1)],1),_c('hr'),_c('CRow',{staticClass:"mb-2"},[_c('CCol',{attrs:{"lg":"12"}},[_c('CDataTable',{attrs:{"items":_vm.details.equipment_history,"fields":[
							{
								key: 'transfer_date', 
								label: 'Transfer Date'
							},
							{
								key: 'origin_id_label', 
								label: 'Origin'
							},
							{
								key: 'current_location_id_label', 
								label: 'Current Location'
							},
							{
								key: 'mts_no', 
								label: 'MTS No.'
							},
							{
								key: 'remarks', 
								label: 'Remarks'
							},
							{
								key: 'users_id_label', 
								label: 'Updated By'
							}
						],"hover":"","border":"","small":"","fixed":""}})],1)],1)],1),_c('div',{staticClass:"w-100",attrs:{"slot":"footer"},slot:"footer"})])}
var staticRenderFns = []

export { render, staticRenderFns }