<template>
	<CModal title="Transfer Equipment" :show.sync="modalState" color="info" size="lg">
		<form @submit.prevent="approve">
			<div class="pl-3 pr-3 pb-3">

				<CRow class="mb-2">
					<CCol lg="6">
						<small class="mr-2">Classification</small>
						<CInput disabled :value="details.classification" type="text" class="mb-2" />
					</CCol>
					 <CCol lg="6" class="mb-1">
						<small class="mr-2">Asset No.</small>
						<CInput disabled :value="details.asset_no" type="text" class="mb-2" />
						<!-- Asset No: <strong>{{details.asset_no ? details.asset_no : "N/A"}}</strong> -->
					</CCol>
					<CCol lg="6" class="mb-1">
						<small class="mr-2">Equipment No</small>
						<CInput disabled :value="details.equipment_prep_no" type="text" class="mb-2" />
					</CCol>
					<!-- Equipment No: <strong>{{details.equipment_prep_no ? details.equipment_prep_no : "N/A"}}</strong> -->
					<CCol lg="6" class="mb-1">
						<small class="mr-2">Current Location</small>
						<CInput disabled :value="(details.current_location ? details.current_location.customer_name:'')" type="text" class="mb-2" />
						
					</CCol>
					

				</CRow>
				<hr />

				<CRow class="mb-2">
					<CCol lg="12">
						<div class="form-group">
							<label clas="mb-1">Transfer Date<span class="text-danger">*</span></label>
							<Datepicker required 
								:typeable="true" 
								v-model="equipmentParams.transfer_date" 
								input-class="form-control bg-white" >
							</Datepicker>
						</div>
					</CCol>
				</CRow>
				<CRow class="mb-2">
					<CCol lg="12">
						<div class="form-group">
							<label class="mb-1">Origin <span class="text-danger">*</span></label>
							<v-select label="customer_name" :options="customerList.data"
								v-model="equipmentParams.origin" placeholder="-Select-">
								<template #search="{ attributes, events }">
									<input class="vs__search" :required="!equipmentParams.origin"
										v-bind="attributes" v-on="events" />
								</template>
							</v-select>
						</div>
					</CCol>
				</CRow>
				<CRow class="mb-2">
					<CCol lg="12">
						<div class="form-group">
							<label class="mb-1">Transfer to <span class="text-danger">*</span></label>
							<v-select label="customer_name" :options="customerList.data"
								v-model="equipmentParams.current_location" placeholder="-Select-">
								<template #search="{ attributes, events }">
									<input class="vs__search" :required="!equipmentParams.current_location"
										v-bind="attributes" v-on="events" />
								</template>
							</v-select>
						</div>
					</CCol>
				</CRow>
				<CRow class="mb-2">
					<CCol lg="12">
						<div class="form-group">
							<label class="mb-1">Depot</label>
							<CInput disabled :value="equipmentParams.current_location.setting_name" type="text" class="mb-2" />
						</div>
					</CCol>
				</CRow>


				<CRow class="mb-2">
					<CCol lg="12">
						<label class="mb-1">MTS No. <span class="text-danger">*</span></label>
						<CInput required v-model="equipmentParams.mts_no" />
					</CCol>
					<CCol lg="12">
						<label class="mb-1">Remarks <span class="text-danger">*</span></label>
						<CTextarea v-model="equipmentParams.remarks" required />
					</CCol>
				</CRow>

			</div>
			<div slot="footer" class="w-100">
				<CButton size="sm" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right"
					type="submit">
					Save
				</CButton>
				<CButton size="sm" style="border-radius: .2rem; color: white;" color="danger" class="ml-1 mr-1 float-right"
					@click="cancel">
					Cancel
				</CButton>
			</div>
		</form>
		<CRow>

		</CRow>

		<div slot="footer" class="w-100"></div>

	</CModal>
</template>
<script>
import config from '../../../config.js';
import axios from '../../../axios';
import Datetime from 'vuejs-datetimepicker';
import Swal from 'sweetalert2';
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';

export default {
	mounted() {
		this.getCustomer();
	},
	props: {
		showModal: false,
		details: {},
	},
	components: { Datetime, vSelect, Datepicker },
	data() {
		return {
			equipmentParams: {
				transfer_date: "",
				current_location: "",
				origin: "",
				remarks: "",
				mts_no: "",
				user_id:""
			},
			customerList: {
				data: []
			},
			modalState: false,
			moment
		}
	},
	watch: {
		details: {
			deep: true,
			handler() {
				this.equipmentParams.transfer_date = this.$options.filters.epochToDate(this.details.transfer_date)
				this.equipmentParams.remarks = this.details.remarks;
				this.equipmentParams.mts_no = this.details.mts_no;
				this.equipmentParams.current_location = this.customerList.data.filter(x => x.id == this.details.current_location_id)[0];
				this.equipmentParams.origin = this.customerList.data.filter(x => x.id == this.details.origin_id)[0];
			}
		},
		showModal: {
			handler() {
				this.modalState = this.showModal
				if (!this.showModal) {
					this.$emit('showModal', false)
				}
			}
		},
		modalState: {
			handler() {
				if (!this.modalState) {
					this.$emit('showModal', false)
				}
			}
		}
	},
	methods: {
		approve() {
			// this.equipmentParams.transfer_date = this.$options.filters.DateToEpoch(this.equipmentParams.transfer_date)
			// this.equipmentParams.transfer_update = true;
			// this.equipmentParams.user_id = config.getUserDetail('id');
			let Equipment_data = {
				transfer_date:  this.$options.filters.DateToEpoch(this.equipmentParams.transfer_date),
				current_location: this.equipmentParams.current_location,
				origin: this.equipmentParams.origin,
				remarks:  this.equipmentParams.remarks,
				mts_no: this.equipmentParams.mts_no,
				user_id:config.getUserDetail('id'),
				transfer_update:true,
			};
			axios.put(config.api_path + '/equipment/' + this.details.id, Equipment_data)
			var text = 'Equipment successfully transferred!';
			Swal.fire({
				title: 'Success!',
				text,
				icon: 'success',
			}).then(response => {
				this.$emit('showModal', false)
			})
		},
		cancel() {
			this.$emit('showModal', false)
		},
		getCustomer() {
			axios.get(config.api_path + '/reference/customer-list', {
				params: {
					show_all: true
				}
			}).then(response => {
				this.customerList = response.data;
			})
		},
	},
}
</script>