<template>
	<CModal :title="'Equipment History '" :show.sync="modalState"  color="info" size="xl"> 
    	<div > 
			
				
				<CRow class="mb-1">
				     <CCol lg="6">
						<small class="mr-2">Classification</small>
						<CInput
		                  disabled
		                  :value="details.classification" 
		                  type="text"
						  class="mb-2"
		                />
					</CCol>		
					<CCol lg="6" class="mb-1">
					<small class="mr-2">Asset No.</small>
						<CInput
		                  disabled
		                  :value="details.asset_no" 
		                  type="text"
						  class="mb-2"
						/>
							
				 </CCol>
        
				  <CCol lg="6" class="mb-1">
					<small class="mr-2">Equipment No</small>
						<CInput
		                  disabled
		                  :value="details.equipment_prep_no" 
		                  type="text"
						  class="mb-2"
						/>
				 </CCol>
				
				  <CCol lg="6" class="mb-1">
					<small class="mr-2">Current Location</small>
						<CInput
		                  disabled
		                  :value="(details.current_location ? details.current_location.customer_name: '')" 
		                  type="text"
						  class="mb-2"
						/>
						
				 </CCol> 
				 
				
        	</CRow>
        	<hr>
    		<CRow class="mb-2">
        		<CCol lg="12">
        			<CDataTable
				      	:items="details.equipment_history"
				      	:fields="[
							{
								key: 'transfer_date', 
								label: 'Transfer Date'
							},
							{
								key: 'origin_id_label', 
								label: 'Origin'
							},
							{
								key: 'current_location_id_label', 
								label: 'Current Location'
							},
							{
								key: 'mts_no', 
								label: 'MTS No.'
							},
							{
								key: 'remarks', 
								label: 'Remarks'
							},
							{
								key: 'users_id_label', 
								label: 'Updated By'
							}
						]"
						hover
						border
						small
						fixed
						
				    >   
				    </CDataTable> 
					 <!-- <CPagination
		          	:active-page.sync="currentPage"
		          	:pages="Math.ceil(dataList.total / 20)"
		          	:activePage="currentPage"
		          	@update:activePage="updatePage"
		        />  -->
        		</CCol> 
        	</CRow> 
    	</div>
    	<!-- <div slot="footer" class="w-100">
    		<CButton style="border-radius: .2rem; color: white;" color="danger" class="ml-1 mr-1 float-right" @click="$emit('showModal', false)">
                Close
            </CButton>  
        </div> -->
    	<div slot="footer" class="w-100"></div>
    </CModal>
</template>
<script>
	import config from '../../../config.js';
	import axios from '../../../axios';
	import Swal from 'sweetalert2'; 
	import Datepicker from 'vuejs-datepicker';
	import vSelect from 'vue-select'
	import 'vue-select/dist/vue-select.css';

	export default{
		

		props: {
			showModal: false,
			details: {
				equipment_prep_no: "",
				classification: "",
				current_location: "",
				asset_no:"",
				equipment_history: []
			},
			booking_number: ""
		},  
		data(){
			return{
				
				modalState: false,
			}
		},
			watch: {
		showModal: {
				handler(){
					this.modalState = this.showModal
					if(!this.showModal){
						this.$emit('showModal', false) 
					} 
				}
			},
			modalState: {
				handler(){
					if(!this.modalState){
						this.$emit('showModal', false) 
					} 
				}
			}},
		
		
		
		

	}
</script>